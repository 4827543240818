.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.main_ring {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
  padding: 5px;
  margin: auto;
  border-radius: 50%;
  border: 5px solid rgba(200, 200, 200, 0.3);
}

.rotate_ring {
  width: 100%;
  height: 100%;
  border: 5px solid rgba(200, 200, 200, 1);
  border-radius: 50%;
  border-left-color: rgba(200, 200, 200, 0.4);
  animation: rotate 1s infinite linear;
  animation-play-state: running;
}

.mdi_ico {
  display: inline-block;
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  width: 24px;
  height: 24px;
  transform: scale(0) translate3d(0, 0, 0);
  font-size: 0;
  text-indent: -9999px;
}

.mdi_ico:nth-of-type(1) {
  animation: loadicons 3s infinite ease-in-out;
  background-image: url(./images/mdi_account_circle.svg);
}

.mdi_ico:nth-of-type(2) {
  animation: loadicons 3s 1s infinite ease-in-out;
  background-image: url(./images/mdi_home.svg);
}

.mdi_ico:nth-of-type(3) {
  animation: loadicons 3s 2s infinite ease-in-out;
  background-image: url(./images/mdi_work.svg);
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loadicons {
  0% {
    transform: scale(0) translate3d(0, 0, 0);
  }
  11% {
    transform: scale(1.2) translate3d(0, 0, 0);
  }
  22% {
    transform: scale(1) translate3d(0, 0, 0);
  }
  33% {
    transform: scale(0) translate3d(0, 0, 0);
  }
}
